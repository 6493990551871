import { template as template_dba19d7fa83843d58a7ec79507d92400 } from "@ember/template-compiler";
const FKLabel = template_dba19d7fa83843d58a7ec79507d92400(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
